<template>
<div class="layout">
  <el-container style="height: 100%; ">
    <el-aside class="menu-container" :width="isCollapse?'65px':'250px'">
      <el-menu
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        :default-openeds="defaultOpeneds"
        :collapse-transition="false"
        :collapse="isCollapse"
        :default-active="defaultActive"
      >
        <template v-for="(item) in menus">
          <el-submenu :index="item.index+''" :key="item.index" v-if="item.path == ''">
            <template slot="title">
              <div class="svg-container" style="margin-right: 5px;">
                <svg-icon :icon-class="item.icon" />
              </div>
              <span slot="title">{{ item.title }}</span>
            </template>
            <el-menu-item v-for="(item2) in item.children" :index="item2.path" :key="item2.index" style="padding-left: 40px;" @click="onMenuItemClick(item2)">
              <template slot="title">
                <span class="svg-container" style="margin-right: 5px;" v-if="item2.icon">
                  <svg-icon :icon-class="item2.icon" />
                </span>
                <span class="svg-container" style="margin-right: 5px;" v-else>
                  <svg-icon icon-class="record-fill" />
                </span>
                <span slot="title">{{ item2.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>

          <el-menu-item :index="item.path" :key="item.index" @click="onMenuItemClick(item)" v-else>
            <template slot="title">
                <span class="svg-container" style="margin-right: 5px;" v-if="item.icon">
                  <svg-icon :icon-class="item.icon" />
                </span>
              <span slot="title">{{ item.title }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header style="font-size: 12px" class="header-box">
        <div class="header-bar">
          <div class="collapse-box" @click="isCollapse = !isCollapse">
            <i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" style="font-size: 30px;"></i>
          </div>

          <div class="right-box" style="float: right">
            <div class="collapse-box" @click="onScreenFullClick">
              <!-- <i :class="isFullscreen?'fas fa-compress-arrows-alt':'fas fa-expand-arrows-alt'" style="font-size: 25px;"></i>1 -->
              <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" style="font-size: 22px;"/>
            </div>
            <el-dropdown trigger="click" @command="handleCommand">
              <el-link :underline="false">
                {{ showName }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="resetPassword">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <el-tabs v-model="tabsValue" type="border-card" @tab-click="onTabClick" @tab-remove="onTabRemove">
          <el-tab-pane
            v-for="(item, index) in tabs"
            :key="item.name"
            :name="item.name"
            :closable="item.closable"
          >

          <!-- <el-tab-pane
            v-for="(item, index) in tabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            :closable="item.closable"
          > -->

            <span slot="label">
              <span class="svg-container" v-if="item.icon">
                <svg-icon :icon-class="item.icon" />
              </span> {{item.title}}</span>
          </el-tab-pane>
        </el-tabs>
      </el-header>

      <el-main class="main-box">
        <keep-alive :include="cachedViews">
          <router-view style="height: 100%;" :key="key"></router-view>
        </keep-alive>
      </el-main>

    </el-container>
  </el-container>

  <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="500px" :close-on-click-modal="false">
    <el-form :model="pwdForm" ref="pwdForm" :rules="pwdRules">
      <el-form-item label="原密码" label-width="80px" prop="p1" :error="p1Error">
        <el-input v-model="pwdForm.p1" autocomplete="off" show-password @focus="p1Error = ''"></el-input>
      </el-form-item>
      <el-form-item label="新密码" label-width="80px" prop="p2" :error="p2Error">
        <el-input v-model="pwdForm.p2" autocomplete="off" show-password @focus="p2Error = ''"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click.native.prevent="changePwd" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import variables from '@/common/styles/variables.scss'
import screenfull from 'screenfull'
import {removeToken} from '@/common/utils/auth.js'
import request from '@/common/utils/request.js'
import util from '@/common/utils/util.js'
import {getMenus, changePwd, logout} from "@/api/home";

export default {
  name: "layout-tabs",
  data() {
    return {
      isCollapse: false,
      isFullscreen: false,

      dialogFormVisible: false,
      loading: false,
      pwdForm: {
        p1: '',
        p2: '',
      },
      pwdRules: {
        p1: [{ required: true, message:"请输入原密码", trigger: 'blur'}],
        p2: [{ required: true, min:6, message:"密码不能少于6个字符", trigger: 'blur'}]
      },
      p1Error:"",
      p2Error:"",
      uname:"",
      cname:"",
      rname:"",
      yyCompanyName:"",
      defaultOpeneds: [],
    }
  },
  computed: {
    menus(){
      return this.$store.state.menus;
    },
    tabs(){
      return this.$store.state.tabs;
    },
    tabsValue: {
      get: function(){
        return this.$store.state.tabsValue;
      },
      set: function(val) {
        this.$store.commit("setTabsValue",val);
      }
    },
    defaultActive: {
      get(){
        return this.$store.state.defaultActive;
      },
      set(val){
        this.$store.commit("setDefaultActive", val);
      }
    },
    cachedViews(){
      return this.$store.state.cachedViews;
    },
    openedViews(){
      return this.$store.state.openedViews;
    },
    key() {
      return this.$route.path
    },
    //defaultOpeneds() {
      // return this.menus.map(function(item){
      //   return item.path;
      // })
    //},
    variables() {
      return variables;
    },
    showName() {
      if(util.isNotEmpty(this.cname)){
        return this.uname+"("+this.cname+")";
      }else if(util.isNotEmpty(this.rname)){
        if (util.isNotEmpty(this.yyCompanyName)){
          return this.uname+"("+this.rname+" | "+this.yyCompanyName+")";
        }else {
          return this.uname+"("+this.rname+")";
        }
      }

      return this.uname;

    }
  },
  mounted() {
    this.uname = util.getCookie("uname");
    this.cname = util.getCookie("cname");
    this.rname = util.getCookie("rname");
    this.yyCompanyName = util.getCookie("yyCompanyName");
    this.$store.commit('addTab',{
      name: "/home",
      title: "首页",
      icon: "dashboard",
      closable: false,
    })
    this.$store.commit("setTabsValue","/home")

    this.$router.replace("/home");

    screenfull.onchange(event => {
      this.isFullscreen = screenfull.isFullscreen;
    })

    // 获取menus
    getMenus().then(res => {
      this.$store.commit("setMenus", res.data);
      this.defaultOpeneds.push(res.data[0].index+"");
    }).catch(res=>{
      this.$message.error("获取用户菜单失败,请重试！");
    })
  },

  methods: {
    changePwd(){
      this.$refs.pwdForm.validate(valid => {
        if(valid){
          this.loading = true;
          this.p1Error = "";
          this.p2Error = "";
          changePwd(this.pwdForm).then(res=>{
            this.loading = false;
            if(res.code == 0){
              if(util.isNotEmpty(res.msg)){
                this.p1Error = res.msg
              }else{
                this.p1Error = res.data.p1
                this.p2Error = res.data.p2
              }
            } else if(res.code == 1) {
              this.$message({
                message: "修改密码成功",
                type: "success"
              });
              this.dialogFormVisible = false;
              setTimeout(()=>{
                this.handleCommand("logout")
              }, 1000)

            }
          }).catch(res=>{
            this.loading = false;
          })
        }else{
          return false;
        }
      });
    },
    doLogout(){
      removeToken();
      util.removeCookie("uname");
      util.removeCookie("cname");
      util.removeCookie("rname");
      this.$store.commit("logout")
      this.$router.replace("/login");
    },
    handleCommand(command) {
      if(command == "logout") {
        logout().then(res=>{
          this.doLogout();
        }).catch(err=>{
          this.doLogout();
        })
      }else if(command == "resetPassword") {
        this.pwdForm.p1 = '';
        this.pwdForm.p2 = '';
        this.dialogFormVisible = true;
      }
    },
    onScreenFullClick(){
      //console.log(screenfull.isFullscreen);
      screenfull.toggle();

    },
    onTabRemove(opt) {
      this.$store.commit("removeTab",{opt:opt, router: (path)=>{this.$router.replace(path)}})
    },
    onTabClick(opt) {
      this.$router.replace(opt.name);
      this.defaultActive=opt.name;
    },
    onMenuItemClick(item) {
      this.$store.commit("openTab",{item:item, router: (path)=>{this.$router.replace(path)}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/common/styles/variables.scss";
.layout{
  height: 100%;
  width: 100%;
}

.menu-container{
  border-right: 1px solid #eee;
  background-color: #304156;
  .el-menu{
    border:none;
    i{
      width: 24px !important;
      font-size: 18px;
      color: $menuText;
    }
    .el-menu-item{
      background-color: #1f2d3d !important;
      &:hover {
        background-color: #001528 !important;
      }
    }
  }
}

.header-box {
  background-color: #FFF;
  color: #333;
  //border-bottom: 1px solid #eee;
  padding: 0px;
  //height: 200px !important;


  .header-bar{
    height: 56px;
    padding-right: 15px;

    .collapse-box{
      // height: 100%;
      width: 50px;
      display: inline-block;
      text-align: center;
      cursor: pointer;

      i {
        padding-top: 15px;
      }

      &:hover {
        color: $--color-primary;
      }
    }

    .right-box {
      display: flex;
      align-items: center;
      height: 100%;

      .el-dropdown {

      }
    }
  }

  .el-tabs {
    border-bottom: none;
    // height: 100%;
    box-shadow: none;
  }

}


.el-aside {
  color: #333;
}

.main-box {
  padding: 5px;

}
</style>

<style lang="scss">
.el-tabs__nav-next, .el-tabs__nav-prev{
  line-height: 39px;
}
.header-box{
  height: auto !important;
  .el-tabs__content{
    padding: 0px 0px !important;
  }
}

// .menu-container .el-submenu .el-menu-item {
//   height: 40px;
//   line-height: 40px;
// }
</style>
