import request from '@/common/utils/request'
import Vue from "vue";

export function getMenus() {
  return request({
    url: Vue.prototype.$adminApiUrl + '/get-menus',
    method: 'post'
  })
}

export function changePwd(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/user/change-pwd',
    method: 'post',
    data
  })
}

export function logout(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/logout',
    method: 'post'
  })
}

export function getDlStatusCount(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/getDlStatusCount',
    method: 'post'
  })
}

export function getDataCount(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/getDataCount',
    method: 'post'
  })
}

export function getQdCount(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/getQdCount',
    method: 'post'
  })
}

export function getChartData1(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/getChartData1',
    method: 'post'
  })
}
export function getyiqingChart(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/activityInfo/numberOfParticipatingStates',
    method: 'get'
  })
}
export function getshenheChart(){
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/participateCountStatus',
    method: 'post'
  })
}
